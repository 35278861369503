import React from 'react';
import { Box, Button, Typography, Grid } from '@mui/material';
import './App.css'; // Tło i grafiki można dodać w tym pliku CSS

const App = () => {
  return (
    <Box
      sx={{
        backgroundImage: `url(${process.env.PUBLIC_URL + '/background.png'})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        minHeight: '100vh',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center',
        color: 'white',
        position: 'relative'
      }}
    >
      <Box
        sx={{
          position: 'absolute',
          top: '50px',
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <Grid
          container
          justifyContent="center"
          spacing={1}
        >
          {[
            { text: 'Zestawy bezalkoholowe', link: '/katalog.pdf#page=3' },
            { text: 'Zestawy z winem', link: '/katalog.pdf#page=12' },
            { text: 'Zestawy z winem ekologicznym', link: '/katalog.pdf#page=25' },
            { text: 'Zestawy z alkoholem', link: '/katalog.pdf#page=33' },
            { text: 'Personalizacja', link: '/katalog.pdf#page=43' }
          ].map((item) => (
            <Grid
              key={item.text}
              item
              xs={12}
              sm={6}
              md={2}
            >
              <a
                href={item.link}
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  textDecoration: 'none',
                  color: '#ffffff',
                  fontSize: '1rem',
                  justifyContent: 'center',
                  fontSize: '1rem',
                  letterSpacinWg: '2px',
                  fontWeight: '400',
                  textTransform: 'uppercase'
                }}
              >
                <Box
                  sx={{
                    height: '100%',
                  }}
                >
                  <Box
                    sx={{
                      width: '25px',
                      height: '25px',
                      borderRadius: '50%',
                      backgroundColor: '#bd9461',
                      marginRight: '10px'
                    }}
                  />
                </Box>
                
                {item.text}
              </a>
            </Grid>
          ))}
        </Grid>
      </Box>
      <Box sx={{ maxWidth: '1200px', padding: '20px', marginTop: '220px' }}>
        <img
          src={`${process.env.PUBLIC_URL + '/logo.png'}`}
          alt="Świąteczne zestawy prezentowe"
          style={{ maxWidth: '100%', height: 'auto', marginBottom: '30px' }}
        />
        <br />
        Z ogromną przyjemnością przedstawiamy katalog prezentów świątecznych rok 2024.<br/>
        W naszym katalogu znajdą Państwo szeroki wybór zestawów delikatesowych, z winem i mocniejszym<br/> alkoholem,
        które będą idealnym prezentem dla najbliższych, pracowników i partnerów biznesowych.
        <br />

        {/* Katalog Button */}
        <a href={`${process.env.PUBLIC_URL + '/katalog.pdf'}`} target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none' }}>
          <Button
            variant="contained"
            className='poppins-semibold'
            color="primary"
            sx={{
              backgroundColor: '#d4a373',
              color: 'white',
              marginTop: '70px',
              padding: '10px 60px ',
              borderRadius: '30px',
              fontFamily: '"Poppins", sans-serif',
              fontWeight: '600',
              fontSize: '20px',
              fontStyle: 'normal',
              letterSpacing: '8px',
            }}
          >
            Katalog 2024
          </Button>
        </a>
      </Box>

      {/* Sekcja Kontakt i Logo */}
      <Grid container  sx={{ marginTop: 'auto', padding: '0px' }}>
       
        <Grid item xs={12} sm={3} >
          <p className='poppins-light' style={{
            fontSize: '1.2rem',
            letterSpacing: '5px'
          }}>
            SKONTAKTUJ SIĘ Z NAMI!
          </p>
        </Grid>
        <Grid item xs={12} sm={3} >

          <p className='poppins-light' style={{
            fontSize: '1.2rem',
            letterSpacing: '5px'
          }}>
            kontakt@okazjonalnysklep.pl
          </p>
        </Grid>
        <Grid item xs={12} sm={3} >
          <p className='poppins-light' style={{
            fontSize: '1.2rem',
          }}>
            510 088 171
          </p>
        </Grid>




        <Grid
          item xs={12} sm={3}
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
            padding: { xs: '20px', sm: '0px' },
          }}
        >
          <img
            src={`${process.env.PUBLIC_URL + '/sklep-z-prezentami.png'}`}
            alt="Okazjonalny Sklep z Prezentami"
            style={{
              maxWidth: '100%',
              height: 'auto',
            }}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default App;